// Here you can add other styles
div.loading {
  .sk-cube-grid {
    width:80px;
    height:80px;
  }
  .sk-cube {
    background-color: #FFF;
  }
  .modal-content {
    background: none;
    border: none;
  }
}
#campaignPicker {
  .form-group {
    margin-bottom: auto;
  }
}

// Announcement
div.announcements {
  div.alert {
    ul {
      margin-bottom: 0;
      padding-left: 1rem;
    }
  }
}
div.announcement {
  ul {
    padding-left: 1rem;
    &.asterisk li {
      text-indent: -0.75rem;
      list-style-type: none;
      &:before {
        display: inline;
        content: "※ ";
      }
    }
  }
  p.modified {
    margin-bottom: 0;
    font-style: italic;
  }
}
// modal 内部 react-jsonschema-form 直下の object フィールドのマージンは不要。
.modal .rjsf > .form-group.field.field-object {
  margin: 0;
}
