// If you want to override variables do it here
@import "variables";
// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui.scss";
// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";
// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";
// If you want to add something do it here
@import "custom";
// ie fixes
@import "ie-fix";

// Spinkit
$spinkit-spinner-color: $body-color;
@import '~spinkit/scss/spinkit.scss';

.navbar-brand {
  margin-left: auto;
  font-size: 0.875rem;

  span {
    display: none;
  }
}

.brand-minimized .navbar-brand > span {
  display: none;
}

@media (min-width: 1200px) {
  .navbar-brand {
    margin-left: 1em;

    span {
      display: inline;
    }

    img {
      margin-right: 0.25em;
    }
  }
}

.sidebar-nav {
  .nav-dropdown-items {
    li:first-child {
      box-shadow: 0 5px 5px rgba(0,0,0,0.25) inset;
    }
    li:last-child {
      box-shadow: 0 -3px 5px rgba(0,0,0,0.25) inset;
    }
  }
}

.campaign-picker {
  .selected-account {
    display: none;
    @media (min-width: 1200px) {
      display: inline;
    }
  }
  li {
    margin-right: 0.25rem;
    a {
      padding: 0.4rem 1rem;
      margin-top: 1px;
    }
  }
}

.Toastify__toast--default {
  color: #555 !important;
}

audio.recorded-audio {
  width: 182px;
  height: 34px;

  &.hidden {
    display: none;
  }
}

.hidden {
  visibility: hidden;
  display: none;
}

.rotateLogo {
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 200px;
  animation: r1 1s linear infinite;

  img {
    width: 100%;
    height: 100%;
  }
}

@keyframes r1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.operation-panel {
  padding: 1rem 1.25rem 0.25rem;
  background-color: #f0f0f0;
  border-top: 3px solid #e0e0e0;
  border-bottom: 1px solid #c8ced3;

  label {
    white-space: nowrap;
  }

  .form-group {
    & > label {
      margin-bottom: 0.25rem;
      padding-bottom: 0.1rem;
      display: block;
      border-bottom: 1px solid #c8ced3;
      color: #1b8eb7;
    }

    & > ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-right: 1rem;
        margin-bottom: 0.5rem;
        display: inline-block;
      }
    }
  }
}
